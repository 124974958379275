import React, { useEffect } from 'react';
import Card from './Card';
import '../css/Dashboard.css';
import { useExistingGlobalState } from '../GlobalState/GlobalState';

const Dashboard = () => {
    let globalState = useExistingGlobalState();
    
    useEffect(() => {
        const receiveMessage = (e) => {
            if (e.data && e.data.key === "token") {
                if (atob(e.data.value) === "sendToken") {
                    let childWindow = e.source;
                    childWindow.postMessage({ key: "token", secondKey: btoa("receiveToken"), value: btoa(globalState.token) }, e.origin);   
                }
            }
        };
        if (window.addEventListener) {
            window.addEventListener("message", receiveMessage, false);
        } else {
            window.attachEvent("message", receiveMessage);
        }
        
        return () => {
            window.removeEventListener("message", receiveMessage);
        }
    }, []);

    return (
        <section className="section-dash">
            <div className="section-logo-background"><img src="/img/logo-x3.svg" alt="logo"/></div>
            <div className="container-cards">
                {
                    globalState.currentUser.userApplications.map(app => app.applicationId !== 1 ? <Card key = {app.applicationId} app = {app}/> : null)
                }
            </div>
            <footer className="footer-dash">
                <p className="credits">Powered by <strong>X3SOLUTIONS</strong> s.r.l.</p>
            </footer>
        </section>
    );    
}

export default Dashboard;