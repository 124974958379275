import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import '../css/LoginForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { useExistingGlobalState, setGlobalState } from '../GlobalState/GlobalState';
import { useHeaders } from '../Utils/Utils';
import { useNotification } from '../GlobalState/Notification';

let state;
let setState;

const LoginForm = () => {
    let globalState = useExistingGlobalState();
    [state, setState] = useState({
        username: "",
        password: "",
        loginError: false
    });

    useEffect(() => {
        setTimeout(() => {
            $('.email > input').focus();
        }, 300);
    }, []);

    useEffect(() => {
        if (state.loginError) {
            $('.warning').empty();
            $('.email > input').css({ 'border': '1px solid red' });
            $('.warning').append('Username o password errati');
        }
    }, [state]);

    return(
        <div className = "full-container">
            <div className="form-container">
                {
                    globalState.isCheckingToken ?
                        <div className="login-loader"></div>
                    :
                        <Form/>
                }
            </div>
            <p className="warning"></p>
        </div>
    );

}

const onKeyDown = (e, type) => {
    let canGoAhead = false;
    if (e.which === 9 || e.keyCode === 9) {
        e.preventDefault();
        if (type === "username") {
            canGoAhead = true;
        } else {
            return;
        }
    }
    if (e.which === 13 || e.keyCode === 13) {
        if (type === "username") {
            canGoAhead = true;
        } else {
            $('.login').click();
        }
    }
    if (canGoAhead) {
        $('.email > input').blur();
        $('.next').click();
    }
}

const onNextClick = (e) => {
    e.preventDefault();
    if (!state.username.trim()) {
        $('.warning').empty();
        $('.email > input').css({ 'border': '1px solid red' });
        $('.warning').append('Username o password errati');
    } else {
        $('.inputs').addClass('shift');
        $('.back').addClass('active-back');
        $('.email > input').css({ 'border': '1px solid #cccccc' });
        $('.warning').empty();
        setTimeout(() => {
        $('.password > input').focus();
        }, 400);
    }
}

const onBackClick = (e) => {
    e.preventDefault();
    $('.inputs').removeClass('shift');
    $('.back').removeClass('active-back');
    setTimeout(() => {
        $('.email > input').focus();
    }, 300);
}

const loginSubmit = (e) => {
    e.preventDefault();

    setGlobalState({ isCheckingToken: true });

    let credentials = {
        username: state.username,
        password: state.password,
        federationId: 1
    };
    fetch("/api/user/login", {
        method: "POST",
        headers: useHeaders(),
        body: JSON.stringify(credentials)
    })
    .then(res => res.ok ? res.json() : null)
    .then(result => {
        let loginError = true;
        if (result) {
            setGlobalState({ token: result.token });
            loginError = false;
        } else {
            setGlobalState({ token: null, isCheckingToken: false });
        }
        setState(prevState => {
            return { ...prevState, ...{ loginError: loginError } };
        });
    });
}

const onChange = (value, field) => {
    setState(prevState => {
        return { ...prevState, ...{ [field]: value } };
    });
}

function Form() {

    return (
        <form className = "login-form">
            <div className="back" onClick = {onBackClick}> <span><FontAwesomeIcon icon = {faArrowCircleLeft}/></span></div>
            <img className = "login-logo" src = "/img/X3.png" alt = "Logo"/>
            <h3 className = "credential-title">Inserisci credenziali</h3>
            <div className="inputs"> 
                <div className="email">
                    <input tabIndex = "-1" className="login-input" type="text" placeholder="Inserisci Username" value = {state.username} onChange = {(e) => onChange(e.target.value, "username")} onKeyDown = {(e) => onKeyDown(e, "username")}/>
                    <button tabIndex = "-1" className="login-button next" onClick = {onNextClick}>Avanti</button>
                </div>
                <div className="password">
                    <input tabIndex = "-1" className="login-input" type="password" placeholder="Inserisci Password" value = {state.password} onChange = {(e) => onChange(e.target.value, "password")} onKeyDown = {(e) => onKeyDown(e, "password")}/>
                    <button type = "button" className = "link-button" style = {{ fontSize: 9, paddingTop: 5 }} onClick = {forgottenPassword}>Password dimenticata?</button>
                    <button style = {{ marginTop: 20 }} tabIndex = "-1" className="login-button login" onClick = {loginSubmit}>Accedi</button>
                </div>
            </div>
        </form>
    );
}

const forgottenPassword = () => {
    setGlobalState({ isCheckingToken: true });
    let {showNotification} = useNotification();
    fetch("/api/forgottenPassword/" + state.username)
    .then(res => {
        if (res.ok) {
            showNotification("info", "E stata inviata una mail con le informazioni per procedere al cambio password!");
            //alert("E stata inviata una mail con le informazioni per procedere al cambio password!");
        } else if(res.status === 404) {
            alert("Utente non esistente!");
        }
        setGlobalState({ isCheckingToken: false });
    });
}

export default LoginForm;