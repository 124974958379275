import { useExistingGlobalState } from '../GlobalState/GlobalState';

export const useHeaders = () => {
    let globalState = useExistingGlobalState();
    return {
        "Content-Type": "application/json",
        "Authorization": "X3Auth " + globalState.token
    }
}

export const PwdPattern = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#$^+=!*()_@%&]).{8,}$";