import React, { Fragment, useEffect, useState } from 'react';
import Menu from './Components/Menu';
import LoginForm from './Components/LoginForm';
import Dashboard from './Components/Dashboard';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import UsersApplication from './Components/UsersApplication';
import Registries from './Components/Registries';
import { useGlobalState, setGlobalState } from './GlobalState/GlobalState';
import Roles from './Enums/Roles';
import UserEdit from './Components/UserEdit';
import PasswordReset from './Components/PasswordReset';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { useNotification } from './GlobalState/Notification';

let state;
let setState;

const App = () => {
  let globalState = useGlobalState({
    token: null,
    currentUser: null,
    isCheckingToken: true,
    isShowingApplication: false
  });

  [state, setState] = useState({ mustShowLoading: true });

  useEffect(() => {
    getCurrentUser(sessionStorage.getItem("token"));
  }, []);

  useEffect(() => {
    if (globalState.token) {
      sessionStorage.setItem("token", globalState.token);
      if (!globalState.currentUser) {
        getCurrentUser(globalState.token);
      }
    } else {
      sessionStorage.removeItem("token")
    }
  }, [globalState.token]);

  let {hideNotification} = useNotification();

  if (state.mustShowLoading) {
    return <LoginForm/>;
  }

  return (
    <BrowserRouter>
      <Fragment>
          {
            globalState.token && globalState.currentUser && !globalState.isShowingApplication
            ?
              <Menu/>
            :
              null
          }
        <Switch>
          <Route exact path = "/(|Dashboard)" render = {() => globalState.token && globalState.currentUser ? <Dashboard/> : <Redirect to = "/Login"/>}/>
          <Route exact path = "/Registries" render = {() => globalState.token && globalState.currentUser && globalState.currentUser.roles.includes(Roles.ADMIN) ? <Registries/> : <Redirect to = "/Login"/>}/>
          <Route exact path = "/Login" render = {() => globalState.token && globalState.currentUser ? <Redirect to = "/Dashboard"/> : <LoginForm/>}/>
          <Route exact path = "/UsersApplication" render = {() => globalState.token && globalState.currentUser && globalState.currentUser.roles.includes(Roles.ADMIN) ? <UsersApplication/> : <Redirect to = "/Login"/>}/>
          <Route exact path = "/UserEdit" render = {() => globalState.token && globalState.currentUser ? <UserEdit/> : <Redirect to = "/Login"/>}/>
          <Route exact path = "/PasswordReset" render = {() => !globalState.token && !globalState.currentUser ? <PasswordReset/> : <Redirect to = "/Login"/>}/>
        </Switch>
        {
          globalState.notification && globalState.notification.active ? 
            <NotificationGroup style = {{ top: 12, margin: "0 auto", width: "100%", zIndex: 15000 }}>
              <Notification
                  type = {{ style: globalState.notification.type, icon: true }}
                  closable={true}
                  onClose={hideNotification}
              >
                <span>
                    {globalState.notification.content}
                </span>
              </Notification>
            </NotificationGroup>
          : null
        }
      </Fragment>
    </BrowserRouter>
  );
}

const getCurrentUser = (token) => {
  setGlobalState({ isCheckingToken: true });
  fetch("/api/user/current", {
    method: "GET",
    headers: { "Authorization": "X3Auth " + token }
  })
  .then(res => res.ok ? res.json() : null)
  .then(result => {
    if (result) {
      setGlobalState({
        token: token,
        isCheckingToken: false,
        currentUser: result
      });
    } else {
      setGlobalState({
        token: null,
        isCheckingToken: false,
        currentUser: null
      });
    }
    setState({ mustShowLoading: false });
  });
}

export default App;
