import { useState } from 'react';

let state;
let setState;

export const useGlobalState = (initialState) => {
    [state, setState] = useState(initialState);
    return state;
}

export const useExistingGlobalState = () => {
    return state;
}

export const setGlobalState = (newState) => {
    setState(prevState => {
        return { ...prevState, ...newState }
    });
}