import { useExistingGlobalState, setGlobalState } from './GlobalState';

export const useNotification = () => {
    let state = useExistingGlobalState();
    if (!state.notification) {
        setGlobalState({
            notification: {
                active: false,
                type: "none",
                content: ""
            }
        });
    }
    return {showNotification, hideNotification};
}

const showNotification = (type, content) => {
    setGlobalState({
        notification: {
            active: true,
            type: type || "none",
            content: content
        }
    });
}

const hideNotification = () => {
    setGlobalState({
        notification: {
            active: false,
            type: "none",
            content: ""
        }
    });
}