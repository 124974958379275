import React, { useState } from 'react';
import { TabStrip, TabStripTab as Tab } from '@progress/kendo-react-layout';
import UsersGrid from './UsersGrid';
import RolesGrid from './RolesGrid';

const Registries = () => {
    let [state, setState] = useState({ selectedTab: 0 });

    return (
        <TabStrip selected = {state.selectedTab} onSelect = {(e) => setState({ selectedTab: e.selected })}>
            <Tab title = "Utenti">
                <UsersGrid/>
            </Tab>
            <Tab title = "Ruoli">
                <RolesGrid/>
            </Tab>
        </TabStrip>
    );
}

export default Registries;