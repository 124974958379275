import React, { Component } from 'react';
import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import $ from 'jquery';
import '../css/Grid.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useHeaders } from '../Utils/Utils';
import { setGlobalState } from '../GlobalState/GlobalState';

export default class RolesGrid extends Component {
    constructor() {
        super();
        this.state = {
            roles: [],
            roleInEdit: null,
            sort: [{
                field: "name",
                dir: "asc"
            }],
            skip: 0,
            take: 15,
            filter: {
                logic: "and",
                filters: []
            }
        };
    }

    headers = useHeaders();

    invalidateToken = () => {
        setGlobalState({ token: null, currentUser: null });
    }

    componentWillMount() {
        fetch("/api/role", {
            method: "GET",
            headers: this.headers
        })
        .then(res => {
            if (res.ok) {
                return res.json();
            }
            if (res.status === 401) {
                this.invalidateToken();
                return;
            }
            return [];
        })
        .then(result => {
            this.setState({ roles: result });
        });
    }

    componentDidMount() {
        $(".k-select").hide();
    }

    insert = () => {
        this.setState({
            roleInEdit: {
                name: "",
                description: ""
            }
        });
    }

    onEditInputChange = (e) => {
        let target = e.target;
        const value = target.type === "textarea" ? target.value : target.value.trim();
        const name = target.props ? target.props.name : target.name;

        this.setState({
            roleInEdit: {
                ...this.state.roleInEdit,
                [name]: value
            }
        });
    }

    save = () => {
        let role = this.state.roleInEdit;
        role.name = role.name.toUpperCase();
        let roles = this.state.roles.slice();
        if (role.id) {
            let i = roles.findIndex(r => r.id === role.id);
            roles[i] = role;
            this.sendData("/api/role/" + role.id, "PUT", role)
            .then(res => {
                if (res.ok) {
                    this.setState({ roles: roles, roleInEdit: null });
                    return;
                }
                if (res.status === 401) {
                    this.invalidateToken();
                    return;
                }
                res.json().then(error => alert(error.message));
            });
        } else {
            this.sendData("/api/role", "POST", role)
            .then(res => {
                if (res.ok) {
                    res.json().then(id => {
                        role.id = id;
                        roles.push(role);
                        this.setState({ roles: roles, roleInEdit: null });
                    });
                    return;
                }
                if (res.status === 401) {
                    this.invalidateToken();
                    return;
                }
                res.json().then(error => alert(error.message));
            });
        }
    }

    sendData = (url, method, data) => {
        return fetch(url, {
            method: method,
            headers: this.headers,
            body: JSON.stringify(data)
        });
    }

    cancel = () => {
        this.setState({ roleInEdit: null });
    }

    edit = (dataItem) => {
        this.setState({ roleInEdit: JSON.parse(JSON.stringify(dataItem)) });
    }

    render() {
        return (
            <div>
                <Grid
                    data = {orderBy(filterBy(this.state.roles, this.state.filter), this.state.sort).slice(this.state.skip, this.state.take + this.state.skip)}
                    sort = {this.state.sort}
                    onSortChange = {(e) => {
                        this.setState({ sort: e.sort });
                    }}
                    sortable
                    skip={this.state.skip}
                    take={this.state.take}
                    total={this.state.roles.length}
                    pageable={true}
                    onPageChange={e => {
                        this.setState({
                            skip: e.page.skip,
                            take: e.page.take
                        });
                    }}
                    filterable
                    filter={this.state.filter}
                    onFilterChange={(e) => {
                        this.setState({
                            filter: e.filter
                        });
                    }}
                    resizable
                >
                    <GridToolbar>
                            <button
                                style={{
                                    background:"#28a745",
                                    color:"#ffffff"
                                }}
                                onClick={this.insert}
                                className="k-button"
                            >
                                Nuovo Ruolo
                            </button>
                    </GridToolbar>
                    <Column field = "name" title = "Nome"/>
                    <Column field = "description" title = "Descrizione"/>
                    <Column title = "Modifica" filterable = {false} sortable = {false} cell = {(props) => 
                        <td>
                            <button
                                className="k-primary k-button"
                                style={{
                                    background:"#007bff",
                                    color:"#ffffff",
                                    borderColor:"#007bff"
                                }}
                                onClick={() => { this.edit(props.dataItem); }}
                            >
                            <FontAwesomeIcon icon={faPen}/>
                            <span className="edit-text" style={{
                                marginLeft:10
                            }}> Modifica</span>
                            </button>
                        </td>
                    }/>
                </Grid>
                {this.state.roleInEdit && <EditDialog ruolo={this.state.roleInEdit} save={this.save} cancel={this.cancel} onChange = {this.onEditInputChange}/>}
            </div>
        );
    }
}

function EditDialog(props) {
    return (
        <Dialog
            width = {300}
            title = {props.ruolo.id ? "Modifica" : "Nuovo Ruolo"}
            onClose={props.cancel}
        >
            <form>
                <div style={{ marginBottom: '1rem' }}>
                    <Input
                        label = "Nome"
                        type="text"
                        name="name"
                        value={props.ruolo.name}
                        onChange={props.onChange}
                    />
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <Input
                        label = "Descrizione"
                        type="textarea"
                        name="description"
                        value={props.ruolo.description || ""}
                        onChange={props.onChange}
                    />
                </div>
            </form>
            <DialogActionsBar>
                <button
                    className="k-button"
                    onClick={props.cancel}
                >
                    Annulla
                </button>
                <button
                    className="k-button k-primary"
                    onClick={props.save}
                >
                    Salva
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}