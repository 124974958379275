import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


export default function Card(props) {
    let app = props.app;
    return (
        <div className="flip-card" onClick={() => {
            window.open(app.entryPoint + 'Login/EmptyLogin', "_blank");
        }}>
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <img src={app.entryPoint + app.icon} alt="Application" className="icon-card" />
                    <div className="front-title">
                        <span>{app.applicationName}</span>
                    </div>
                </div>
                <div className="flip-card-back">
                    <h1>{app.applicationName}</h1>
                    <p className="back-desc">{app.description}</p>
                    <FontAwesomeIcon className="back-arrow" icon={faArrowRight} />
                </div>
            </div>
      </div>
  );
}