import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import '../css/Menu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faHome, faUsers, faUserLock, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { useExistingGlobalState, setGlobalState } from '../GlobalState/GlobalState';
import Roles from '../Enums/Roles';

const Menu = () => {
    let globalState = useExistingGlobalState();

    return (
        <div className="menu-containter">
            <div className="menu-trama"></div>
            <div className="overlay-clock"></div>
            <div className="div-clock" disabled>
                <embed src="https://www.zeitverschiebung.net/clock-widget-iframe-v2?language=en&size=large&timezone=Europe%2FRome" enabled="false" width="100%" height="140" frameBorder="0" disabled/>
            </div>
            <div className="button-menu-container">
                <input type="checkbox" href="#" className="menu-open" name="menu-open" id="menu-open" />
                <label className="menu-open-button" htmlFor="menu-open">
                    <span className="lines line-1"></span>
                    <span className="lines line-2"></span>
                    <span className="lines line-3"></span>
                </label>
                <Link title = "Home" to = "/" className="menu-item blue" > <FontAwesomeIcon icon = {faHome}/> </Link>
                <Link title = "User Edit" to = "/UserEdit" className="menu-item purple"> <FontAwesomeIcon icon = {faUserLock}/> </Link>
                {
                    globalState.currentUser.roles.includes(Roles.ADMIN)
                    ?
                        <Fragment>
                            <Link title = "Registries" to = "/Registries" className="menu-item green"> <FontAwesomeIcon icon = {faUsers}/> </Link>
                            <Link title = "Users Applications"  to = "/UsersApplication" className="menu-item orange" > <FontAwesomeIcon icon = {faExchangeAlt}/> </Link>
                        </Fragment>
                    :
                        null
                }
                <Link title = "Logout" to = "/Login" onClick = {() => setGlobalState({ token: null, currentUser: null })} className="menu-item red"> <FontAwesomeIcon icon = {faSignOutAlt}/> </Link>
                {/*
                <Link to = "" className="menu-item lightblue"> <FontAwesomeIcon icon = {faAnchor}/> </Link>*/}
            </div>
        </div>
    );
}

export default Menu;