import React, { useState, useEffect, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Input } from '@progress/kendo-react-inputs';
import '../css/PasswordReset.css';
import { Button } from '@progress/kendo-react-buttons';
import { useNotification } from '../GlobalState/Notification';
import { PwdPattern } from '../Utils/Utils';
import { useHeaders } from '../Utils/Utils';

const PasswordReset = () => {
    let [state, setState] = useState({
        password: "",
        passwordConfirm: "",
        pwdToken: null,
        isCheckingToken: true
    });

    useEffect(() => {
        var search = window.location.search.substring(1);
        if (search) {
            let queryObj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
            let pwdToken = queryObj.pwdToken;
            fetch("/api/forgottenPassword/checkToken?token=" + pwdToken)
            .then(res => {
                if (res.ok) {
                    setState({ ...state, pwdToken: pwdToken, isCheckingToken: false });
                } else {
                    setState({ ...state, isCheckingToken: false });
                }
            });
        } else {
            setState({ ...state, isCheckingToken: false });
        }
    }, []);

    let {showNotification} = useNotification();

    if (!state.isCheckingToken && !state.pwdToken) {
        return <Redirect to = "/Login"/>
    }

    const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.value });
    }

    const isButtonDisabled = () => {
        if (!state.password || state.password.trim() === "") {
            return true;
        }
        if (state.password && state.password.trim() !== "") {
            if (!state.password.match(PwdPattern) || state.password !== state.passwordConfirm) {
                return true;
            }
        }
        return false;
    }

    const save = () => {
        var data = {
            token: state.pwdToken,
            password: state.password && state.password.trim() !== "" ? state.password : null
        }
        fetch("/api/forgottenPassword/setPassword", {
            method: "POST",
            headers: useHeaders(),
            body: JSON.stringify(data)
        })
        .then(res => {
            if (res.ok) {
                setState({
                    ...state, pwdToken: null
                });
                return;
            }
            res.json().then(error => alert(error.message));
        });
    }

    return (
        <div className = "form-reset-full">
            <div className = "form-reset-container" id = "pwdResetContainer">
                <img style = {styles.logo} src = "/img/X3.png" alt = "Logo"/>
                <h3 style = {styles.title}>Ripristino Password</h3>
                <span style = {styles.input}>
                    <Input
                        label = "Nuova Password"
                        type = "password"
                        style = {{ width: "calc(100% - 30px)", borderRight: 0 }}
                        pattern = {PwdPattern}
                        value = {state.password}
                        name = "password"
                        onChange = {onChange}
                    />
                    <Button
                        icon = "question"
                        primary = {true}
                        style = {{ border: "none", background: "white", borderRadius: "0 2px 2px 0", color: "#ff6358", verticalAlign: "bottom" }}
                        onClick = {() => showNotification("info", 
                            <Fragment>
                                La nuova password dovrà contenere minimo 8 caratteri, di cui:
                                <br/>
                                -almeno una lettera maiuscola
                                <br/>
                                -almeno una lettera minuscola
                                <br/>
                                -almeno un numero
                                <br/>
                                -almeno un carattere speciale
                            </Fragment>
                        )}
                    />
                </span>
                <Input
                    label = "Conferma Password"
                    type = "password"
                    name = "passwordConfirm"
                    style = {styles.input}
                    value = {state.passwordConfirm}
                    valid = {state.password && state.password.trim() !== '' ? state.password !== state.passwordConfirm ? false : true : state.passwordConfirm ? false : true}
                    onChange = {onChange}
                />
                <Button
                    style = {styles.button}
                    primary = {true}
                    disabled = {isButtonDisabled()}
                    onClick = {save}
                >
                    Salva
                </Button>
            </div>
        </div>
    );
}

const styles = {
    input: {
        width: "80%",
        color: "#ddd",
        margin: "0 auto",
        marginBottom: 10
    },
    logo: {
        margin: "0 auto",
        width: "70px",
        height: "79px"
    },
    title: {
        width: "100%",
        color: "#ffffff"
    },
    button: {
        margin: "0 auto",
        width: "80%",
        background: "rgb(40, 167, 69)",
        border: "none",
        marginTop: 15,
        paddingTop: 10,
        paddingBottom: 10
    }
}

export default PasswordReset;