import React, { Component, Fragment } from 'react';
import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import $ from 'jquery';
import '../css/Grid.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useHeaders, PwdPattern } from '../Utils/Utils';
import { setGlobalState } from '../GlobalState/GlobalState';
import {Button} from "@progress/kendo-react-buttons";
import {useNotification} from "../GlobalState/Notification";

export default class UsersGrid extends Component {
    constructor() {
        super();
        this.state = {
            users: [],
            userInEdit: null,
            sort: [{
                field: "name",
                dir: "asc"
            }],
            skip: 0,
            take: 15,
            filter: {
                logic: "and",
                filters: []
            }
        };
    }

    headers = useHeaders();

    invalidateToken = () => {
        setGlobalState({ token: null, currentUser: null });
    }

    componentWillMount() {
        fetch("/api/user", {
            method: "GET",
            headers: this.headers
        })
        .then(res => {
            if (res.ok) {
                return res.json();
            }
            if (res.status === 401) {
                this.invalidateToken();
                return;
            }
            return [];
        })
        .then(result => {
            this.setState({ users: result });
        });
    }

    componentDidMount() {
        $(".k-select").hide();
    }

    insert = () => {
        this.setState({ 
            userInEdit: {
                name: "",
                surname: "",
                userName: "",
                password: "",
                confirmPassword: "",
                email: "",
                phone: "",
                active: true
            } 
        });
    }

    onEditInputChange = (e) => {
        let target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trim();
        const name = target.props ? target.props.name : target.name;

        this.setState({
            userInEdit: {
                ...this.state.userInEdit,
                [name]: value
            }
        });
    }

    save = () => {
        let user = this.state.userInEdit;
        if (user.password && user.password.trim() !== "" && (!user.password.match(PwdPattern) || user.password !== user.confirmPassword)) {
            return;
        }
        let users = this.state.users.slice();
        if (user.id) {
            let i = users.findIndex(u => u.id === user.id);
            users[i] = user;
            this.sendData("/api/user/" + user.id, "PUT", user)
            .then(res => {
                if (res.ok) {
                    this.setState({ users: users, userInEdit: null });
                    return;
                }
                if (res.status === 401) {
                    this.invalidateToken();
                    return;
                }
                res.json().then(error => alert(error.message));
            });
        } else {
            this.sendData("/api/user/register", "POST", user)
            .then(res => {
                if (res.ok) {
                    res.json().then(id => {
                        user.id = id;
                        user.password = "";
                        users.push(user);
                        this.setState({ users: users, userInEdit: null });
                    });
                    return;
                }
                if (res.status === 401) {
                    this.invalidateToken();
                    return;
                }
                res.json().then(error => alert(error.message));
            });
        }
        
    }

    sendData = (url, method, data) => {
        data.defaultFederationId = 1;
        return fetch(url, {
            method: method,
            headers: this.headers,
            body: JSON.stringify(data)
        });
    }

    cancel = () => {
        this.setState({ userInEdit: null });
    }

    edit = (dataItem) => {
        this.setState({ userInEdit: JSON.parse(JSON.stringify(dataItem)) });
    }

    render() {
        return (
            <div>
                <Grid
                    data = {orderBy(filterBy(this.state.users, this.state.filter), this.state.sort).slice(this.state.skip, this.state.take + this.state.skip)}
                    sort = {this.state.sort}
                    onSortChange = {(e) => {
                        this.setState({ sort: e.sort });
                    }}
                    sortable
                    skip={this.state.skip}
                    take={this.state.take}
                    total={this.state.users.length}
                    pageable={true}
                    onPageChange={e => {
                        this.setState({
                            skip: e.page.skip,
                            take: e.page.take
                        });
                    }}
                    filterable
                    filter={this.state.filter}
                    onFilterChange={(e) => {
                        this.setState({
                            filter: e.filter
                        });
                    }}
                    resizable
                >
                    <GridToolbar>
                            <button
                                style={{
                                    background:"#28a745",
                                    color:"#ffffff"
                                }}
                                onClick={this.insert}
                                className="k-button"
                            >
                                Nuovo Utente
                            </button>
                    </GridToolbar>
                    <Column field = "name" title = "Nome"/>
                    <Column field = "surname" title = "Cognome"/>
                    <Column field = "userName" title = "Username"/>
                    <Column field = "email" title = "Email"/>
                    <Column field = "phone" title = "Telefono"/>
                    <Column field = "active" title = "Attivo" filterable = {false} sortable = {false} cell = {(props) => <td><span className = {props.dataItem.active ? "k-icon k-i-check" : "k-icon k-i-close"}></span></td>}/>
                    <Column title = "Modifica" filterable = {false} sortable = {false} cell = {(props) => 
                        <td>
                            <button
                                className="k-primary k-button"
                                style={{
                                    background:"#007bff",
                                    color:"#ffffff",
                                    borderColor:"#007bff"
                                }}
                                onClick={() => { this.edit(props.dataItem); }}
                            >
                            <FontAwesomeIcon icon={faPen}/>
                            <span className="edit-text" style={{
                                marginLeft:10
                            }}> Modifica</span>
                            </button>
                        </td>
                    }/>
                </Grid>
                {this.state.userInEdit && <EditDialog utente={this.state.userInEdit} save={this.save} cancel={this.cancel} onChange = {this.onEditInputChange}/>}
            </div>
        );
    }
}

function EditDialog(props) {
    let {showNotification} = useNotification();
    return (
        <Dialog
            width = {300}
            title = {props.utente.id ? "Modifica" : "Nuovo Utente"}
            onClose={props.cancel}
        >
            <form>
                <div style={{ marginBottom: '1rem' }}>
                    <Input
                        label = "Nome"
                        type="text"
                        name="name"
                        value={props.utente.name}
                        onChange={props.onChange}
                    />
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <Input
                        label = "Cognome"
                        type="text"
                        name="surname"
                        value={props.utente.surname}
                        onChange={props.onChange}
                    />
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <Input
                        label = "Username"
                        type="text"
                        name="userName"
                        value={props.utente.userName}
                        onChange={props.onChange}
                    />
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <Input
                        label = "Email"
                        type="email"
                        name="email"
                        value={props.utente.email}
                        onChange={props.onChange}
                    />
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <Input
                        label = "Telefono"
                        type="tel"
                        name="phone"
                        value={props.utente.phone || ""}
                        onChange={props.onChange}
                    />
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <Input
                        label = "Password"
                        type="password"
                        name="password"
                        pattern = {PwdPattern}
                        value={props.utente.password}
                        onChange={props.onChange}
                    />
                    <Button
                        icon = "question"
                        primary = {true}
                        style = {{ border: "none", background: "#F8F8FF", borderRadius: "0 2px 2px 0", color: "#ff6358", verticalAlign: "bottom" }}
                        onClick = {(e) => {e.preventDefault(); showNotification("none",
                            <Fragment>
                                La nuova password dovrà contenere minimo 8 caratteri, di cui:
                                <br/>
                                -almeno una lettera maiuscola
                                <br/>
                                -almeno una lettera minuscola
                                <br/>
                                -almeno un numero
                                <br/>
                                -almeno un carattere speciale
                            </Fragment>
                        );}}
                    />
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <Input
                        label = "Conferma Password"
                        type = "password"
                        name = "confirmPassword"
                        value = {props.utente.confirmPassword}
                        valid = {props.utente.confirmPassword && props.utente.confirmPassword.trim() !== '' ? props.utente.password === props.utente.confirmPassword : !props.utente.confirmPassword}
                        onChange = {props.onChange}
                    />
                </div>
                <div>
                    <label>Attivo</label>
                    <br/>
                    <input
                        style = {{marginLeft: 8}}
                        type="checkbox"
                        name="active"
                        checked={props.utente.active}
                        disabled={props.utente.id ? false : true}
                        onChange={props.onChange}
                    />
                </div>
            </form>
            <DialogActionsBar>
                <button
                    className="k-button"
                    onClick={props.cancel}
                >
                    Annulla
                </button>
                <button
                    className="k-button k-primary"
                    onClick={props.save}
                >
                    Salva
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}