import React, { useState, Fragment, useEffect } from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import '../css/UserApplication.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { DropDownButton } from '@progress/kendo-react-buttons';
import { setGlobalState } from '../GlobalState/GlobalState';
import { useHeaders } from '../Utils/Utils';

let state;
let setState;
let headers;

let setReducedState = (newState) => {
    setState(prevState => {
        return { ...prevState, ...newState };
    });
}

const UsersApplication = () => {
    [state, setState] = useState({
        users: [],
        freeUsers: [],
        applicationRoles: [],
        roles: [],
        applications: [],
        selectedApplication: null
    });

    headers = useHeaders();

    useEffect(() => {
        fetch("/api/application", {
            method: "GET",
            headers: headers
        })
        .then(res => {
            if (res.ok) {
                return res.json();
            }
            if (res.status === 401) {
                invalidateToken();
                return;
            }
            return [];
        })
        .then(result => {
            setReducedState({ applications: result });
        });
        fetch("/api/user", {
            method: "GET",
            headers: headers
        })
        .then(res => {
            if (res.ok) {
                return res.json();
            }
            if (res.status === 401) {
                invalidateToken();
                return;
            }
            return [];
        })
        .then(result => {
            setReducedState({ users: result, freeUsers: result });
        });
        fetch("/api/role", {
            method: "GET",
            headers: headers
        })
        .then(res => {
            if (res.ok) {
                return res.json();
            }
            if (res.status === 401) {
                invalidateToken();
                return;
            }
            return[];
        })
        .then(result => {
            setReducedState({ roles: result });
        });
    }, []);

    return (
            <Fragment>
                <div className = "app-select">
                    <DropDownList
                        data = {state.applications}
                        textField = "applicationName"
                        defaultItem = {
                            {
                                applicationName: "Seleziona un'applicazione..."
                            }
                        }
                        style = {{ width: "250px" }}
                        value = {state.selectedApplication}
                        dataItemKey = "applicationId"
                        onChange = {(e) => {
                            setReducedState({ selectedApplication: e.target.value, freeUsers: state.users,  applicationRoles: [] });
                            if (e.target.value.applicationId) {
                                getApplicationUsers(e.target.value.applicationId);
                            }
                        }}
                    />
                </div>
                    <div className="dd-container">
                        <div className="dd-panel"
                            style={{
                                background:'#f5f5f5',
                                border:1,
                                borderColor:'#007bff',
                                borderStyle:'solid',
                            }}
                        >
                            <div 
                                style={{
                                    padding:15,
                                    color:'#ffffff',
                                    background:'#007bff'
                                }}
                            >
                                <span><b>Utenti</b></span>
                            </div>
                            <Container
                                groupName = "Users"
                                style= {{
                                    border:3,
                                    borderColor:'#cccccc',
                                    borderStyle:'dashed',
                                    padding:10,
                                    background:'#ffffff',
                                    margin:10,
                                    maxHeight: 400
                                }}
                                onDrop = {({removedIndex, addedIndex, payload}) => {
                                    let freeUsers = state.freeUsers.slice();
                                    if (removedIndex || removedIndex === 0) {
                                        if (!addedIndex && addedIndex !== 0) {
                                            return;
                                        }
                                        freeUsers.splice(removedIndex, 1);
                                    }
                                    if ((addedIndex || addedIndex === 0) && !freeUsers.map(u => u.id).includes(payload.id)) {
                                        freeUsers.splice(addedIndex, 0, payload);
                                    }
                                    setReducedState({ freeUsers: freeUsers });
                                }}
                                getChildPayload = { index =>
                                    state.freeUsers[index]
                                }
                                dragClass = "user-ghost"
                                dropClass = "user-ghost-drop"
                            >
                                {
                                    state.freeUsers.map(u => 
                                        <Draggable key = {u.id}>
                                            <div 
                                                style = {styles.draggable}
                                            >
                                                {u.name + " " + u.surname}
                                            </div>
                                        </Draggable>
                                    )
                                }
                            </Container>
                        </div>

                        {
                            state.selectedApplication && state.selectedApplication.applicationId
                            ?
                            <div
                                className = "dd-bigPanel"
                                style = {{
                                    background: "rgb(245, 245, 245)",
                                    border: "1px solid rgb(0, 123, 255)",
                                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                    margin: "3%"
                                }}
                            >
                                <div 
                                    style={{
                                        padding:15,
                                        color:'#ffffff',
                                        background:'#007bff',
                                        textAlign: "center"
                                    }}
                                >
                                    <span><b>{state.selectedApplication.applicationName}</b></span>
                                    <DropDownButton
                                        icon = "plus"
                                        primary = {true}
                                        className = "dropdown-roleButton"
                                        buttonClass = "roleButton"
                                        items = {state.roles.filter(r => !state.applicationRoles.map(er => er.role.id).includes(r.id))}
                                        textField = "name"
                                        onItemClick = {(e) => {
                                            let appRoles = state.applicationRoles;
                                            appRoles.push({
                                                role: e.item,
                                                users: []
                                            });
                                            setReducedState({ applicationRoles: appRoles });
                                        }}
                                    />
                                </div>
                                <Container
                                    style = {{
                                        display:"flex",
                                        flexWrap:"wrap"
                                    }}
                                    orientation = "horizontal"
                                    groupName = "Roles"
                                    getChildPayload = {index =>
                                        state.applicationRoles[index]
                                    }
                                    onDrop = {({removedIndex, addedIndex, payload}) => {
                                        var roles = state.applicationRoles.slice();
                                        roles.splice(removedIndex, 1);
                                        roles.splice(addedIndex, 0, payload);
                                        setReducedState({ applicationRoles: roles });
                                    }}
                                    dragHandleSelector = ".draggable-handler"
                                >
                                    {
                                        state.applicationRoles.map(roleUsers =>
                                            <Draggable key = {roleUsers.role.id}>
                                                <div
                                                    style = {{
                                                        marginTop: 10,
                                                        marginLeft: 20,
                                                        marginRight: 20
                                                    }}
                                                >
                                                    <div
                                                    style={{
                                                        paddingLeft:15,
                                                        paddingRight:15,
                                                        paddingTop: 15
                                                    }}
                                                    >
                                                        <FontAwesomeIcon className = "draggable-handler" style = {{ cursor: "all-scroll", float: "left" }} icon = {faGripVertical}/>
                                                        <span><b>{roleUsers.role.name}</b></span>
                                                    </div>
                                                    <Container
                                                        groupName = "Users"
                                                        style= {{
                                                            border:3,
                                                            borderColor:'#cccccc',
                                                            borderStyle:'dashed',
                                                            padding:10,
                                                            background:'#ffffff',
                                                            margin:10,
                                                            maxHeight: 360
                                                        }}
                                                        getChildPayload = { index =>
                                                            roleUsers.users[index]
                                                        }
                                                        onDrop = {({removedIndex, addedIndex, payload}) => {
                                                            let roles = state.applicationRoles.slice();
                                                            let roleIndex = roles.findIndex(r => r.role.id === roleUsers.role.id);
                                                            if (removedIndex || removedIndex === 0) {
                                                                if (!addedIndex && addedIndex !== 0) {
                                                                    changeApplicationUser(payload.id, roleUsers.role.id, "DELETE");
                                                                }
                                                                roles[roleIndex].users.splice(removedIndex, 1);
                                                            }
                                                            if (addedIndex || addedIndex === 0) {
                                                                if (!roles[roleIndex].users.map(u => u.id).includes(payload.id)) {
                                                                    if (!removedIndex && removedIndex !== 0) {
                                                                        changeApplicationUser(payload.id, roleUsers.role.id, "POST");
                                                                    }
                                                                    roles[roleIndex].users.splice(addedIndex, 0, payload);
                                                                }
                                                            }
                                                            setReducedState({ applicationRoles: roles });
                                                        }}
                                                        dragClass = "user-ghost"
                                                        dropClass = "user-ghost-drop"
                                                    >
                                                        {
                                                            roleUsers.users.map(u => 
                                                                <Draggable key = {u.id}>
                                                                    <div 
                                                                        style = {styles.draggable}
                                                                    >
                                                                        {u.name + " " + u.surname}
                                                                    </div>
                                                                </Draggable>
                                                            )
                                                        }
                                                    </Container>
                                                </div>
                                            </Draggable>
                                        )
                                    }
                                </Container>
                            </div>
                            :
                                null
                        }
                    </div>
            </Fragment>
        );

}

const invalidateToken = () => {
    setGlobalState({ token: null, currentUser: null });
}

const getApplicationUsers = (applicationId) => {
    fetch("/api/application/users/" + applicationId, {
        method: "GET",
        headers: headers
    })
    .then(res => {
        if (res.ok) {
            return res.json();
        }
        if (res.status === 401) {
            invalidateToken();
            return;
        }
        return [];
    })
    .then(result => {
        setReducedState({ applicationRoles: result });
    });
}

const changeApplicationUser = (idUtente, idRuolo, method) => {
    var request = {
        applicationId: state.selectedApplication.applicationId,
        userId: idUtente,
        RoleId: idRuolo
    }
    fetch("/api/application/user", {
        method: method,
        headers: headers,
        body: JSON.stringify(request)
    })
    .then(res => {
        if (res.ok) {
            return;
        }
        if (res.status === 401) {
            invalidateToken();
            return;
        }
        return;
    });
}

const styles = {
    draggable: {
        margin:10,
        padding:15,
        background:'#ffffff',
        cursor: "all-scroll",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)"
    }
}

export default UsersApplication;