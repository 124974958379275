import React, { Fragment, useState } from 'react';
import { useExistingGlobalState, setGlobalState } from '../GlobalState/GlobalState';
import '../css/UserEdit.css';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { useHeaders, PwdPattern } from '../Utils/Utils';
import { useNotification } from '../GlobalState/Notification';

let state;
let setState;
let globalState;

const UserEdit = () => {
    globalState = useExistingGlobalState();

    [state, setState] = useState({
        email: "",
        newPassword: "",
        confirmPassword: "",
        oldPassword: "",
        isValidEmail: true,
        isCurrentPasswordValid: true
    });

    let {showNotification} = useNotification();

    return (
        <Fragment>
            <h3 className = "form-edit-title">Modifica Utente</h3>
            <div className = "form-edit-container">
                <div className = "form-edit-input">
                    <Input
                        label = "Nome"
                        value = {globalState.currentUser.name}
                        style = {inputStyle}
                        disabled
                    />
                    <Input
                        label = "Cognome"
                        value = {globalState.currentUser.surname}
                        style = {inputStyle}
                        disabled
                    />
                    <Input
                        label = "Username"
                        value = {globalState.currentUser.userName}
                        style = {inputStyle}
                        disabled
                    />
                </div>
                <div className = "form-edit-input">
                    <Input
                        label = "Email"
                        placeholder = {globalState.currentUser.email}
                        style = {inputStyle}
                        type = "email"
                        name = "email"
                        onChange = {onChange}
                    />
                    <Input
                        label = "Telefono"
                        placeholder = {globalState.currentUser.phone}
                        style = {inputStyle}
                        type = "tel"
                        name = "phone"
                        onChange = {onChange}
                    />
                    <span style = {inputStyle}>
                        <Input
                            label = "Nuova Password*"
                            type = "password"
                            style = {{ width: "calc(100% - 30px)", borderRight: 0, marginBottom: 10 }}
                            value = {state.newPassword}
                            pattern = {PwdPattern}
                            name = "newPassword"
                            onChange = {onChange}
                        />
                        <Button
                            icon = "question"
                            primary = {true}
                            style = {{ border: "none", background: "white", borderRadius: "0 2px 2px 0", color: "#ff6358", verticalAlign: "bottom", marginBottom: 10 }}
                            onClick = {() => showNotification("info", 
                                <Fragment>
                                    La nuova password dovrà contenere minimo 8 caratteri, di cui:
                                    <br/>
                                    -almeno una lettera maiuscola
                                    <br/>
                                    -almeno una lettera minuscola
                                    <br/>
                                    -almeno un numero
                                    <br/>
                                    -almeno un carattere speciale
                                </Fragment>
                            )}
                        />
                    </span>
                    <Input
                        label = "Conferma Password"
                        type = "password"
                        name = "confirmPassword"
                        style = {inputStyle}
                        value = {state.confirmPassword}
                        valid = {state.newPassword && state.newPassword.trim() !== '' ? state.newPassword !== state.confirmPassword ? false : true : state.confirmPassword ? false : true}
                        onChange = {onChange}
                    />
                </div>
            </div>
            <div className = "form-edit-confirm-container">
                <div className = "form-edit-confirm-input">
                    <Input
                        label = "Password Corrente"
                        type = "password"
                        style = {{ color: "#007bff", width: "100%" }}
                        name = "oldPassword"
                        onChange = {onChange}
                        valid = {state.isCurrentPasswordValid}
                        value = {state.oldPassword}
                    />
                </div>
                <div className = "form-edit-confirm-input">
                    <Button
                        style = {{ float: "right", marginTop: 17, background: "rgb(40, 167, 69)", border: "none" }}
                        primary = {true}
                        disabled = {isButtonDisabled()}
                        onClick = {save}
                    >
                        Salva
                    </Button>
                </div>
            </div>
        </Fragment>
    );
}

const inputStyle = {
    width: "100%",
    color: "#ddd",
    marginBottom: 10
}

const onChange = (e) => {
    if (e.target.name === "email") {
        setState({ ...state, [e.target.name]: e.value, isValidEmail: e.target.validity.valid })
    } else {
        setState({ ...state, [e.target.name]: e.value });
    }
}

const isButtonDisabled = () => {
    if (!state.oldPassword || state.oldPassword.trim() === "") {
        return true;
    }
    if (state.email && state.email.trim() !== "") {
        if (!state.isValidEmail) {
            return true;
        }
    }
    if (state.newPassword && state.newPassword.trim() !== "") {
        if (!state.newPassword.match(PwdPattern) || state.newPassword !== state.confirmPassword) {
            return true;
        }
    }
    return false;
}

const save = () => {
    var data = {
        email: state.email && state.email.trim() !== "" ? state.email : globalState.currentUser.email,
        oldPassword: state.oldPassword,
        password: state.newPassword && state.newPassword.trim() !== "" ? state.newPassword : null
    }
    fetch("/api/user", {
        method: "PUT",
        headers: useHeaders(),
        body: JSON.stringify(data)
    })
    .then(res => {
        if (res.status === 403) {
            setState({ ...state, isCurrentPasswordValid: false });
            return;
        }
        if (res.ok || res.status === 401) {
            setGlobalState({ token: null, currentUser: null });
            return;
        }
        res.json().then(error => alert(error.message));
    });
}

export default UserEdit;